(function () {
    'use strict';

    angular
        .module('app')
        .factory('viewerCustomization', viewerCustomization);

    viewerCustomization.$inject = ['$location', '$log', 'viewerLogo'];

    function viewerCustomization($location, $log, viewerLogo) {
        var customParams = $location.search();
        const defaultLogo = 'BedMatchLogo.png';

        return {
            shouldDisplayAccount: shouldDisplayAccount,
            shouldDisplayConsumer: shouldDisplayConsumer, 
            getLogo: getLogo
        };
        
        function shouldDisplayAccount(account) {
            return (isUsingWhitelist() && isInAccountList(account)) || 
                   (isUsingBlacklist() && !isInAccountList(account));
        }

        function shouldDisplayConsumer(account) {
            return hideConsumerForAccountList().length < 1 || 
                !hideConsumerForAccountList().includes(account.trim());
        }

        function isUsingWhitelist() {
            return display() !== 'hide';
        }

        function isUsingBlacklist() {
            return display() === 'hide';
        }

        function isInAccountList(account) {
            return accountList().length < 1 || accountList().includes(account.trim());
        }

        function getLogo() {
            if (logo().length < 1)
                return getDefaultImage();
            else
                return getCustomImage();
        }

        function getCustomImage() {
            return viewerLogo.getLogo(logo())
                .then(function(resp) {
                    return displayImage(resp.data);
                }).catch(function () {
                    $log.warn('An error occured while retrieving the user-specified logo. Falling back to the default image.');
                    return getDefaultImage();
                });
        }

        function getDefaultImage() {
            return viewerLogo.getLogo(defaultLogo)
                .then(function (resp) {
                    return displayImage(resp.data);
                });
        }

        function displayImage(binaryFile) {
            var logoAsStr = _arrayBufferToBase64(binaryFile);
            return 'data:image/PNG;base64, ' + logoAsStr;           
        }


        function _arrayBufferToBase64(buffer) {
            var binary = '';
            var bytes = new Uint8Array(buffer);
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
        }


        function display() {
            return isValid(customParams.display)
                    ? customParams.display
                    : 'show';
        }

        function accountList() {
            return isValid(customParams.accounts)
                    ? customParams.accounts.split(',')
                    : [];
        }

        function logo() {
            return isValid(customParams.logo)
                    ? customParams.logo
                    : '';
        }

        function hideConsumerForAccountList() {
            return isValid(customParams.hideConsumerForAccounts)
                ? customParams.hideConsumerForAccounts.split('|')
                : [];
        }

        function isValid(setting) {
            return typeof (setting) != 'undefined';
        }
    }
})();