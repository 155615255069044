(function() {
    'use strict';

    angular
        .module('app')
        .constant(
            'profileSimulationConfig',
            {
                autoStartProfileSync: true,
                maxGapBetweenUpdate: 6000,
                realtimeIterationRate: 25,
                sleepTimeWhenPaused: 1000,
                sleepTimeWhenNoProfilesAvailable: 1000,
                sleepTimeBetweenSync: 240000,
                useOfflineProfiles: false,
                useOfflineMaps: true,
                maximumNumberOfPushPins: 200,
                maximumRecentProfileCount: 10,
                viewerZoomDuration: 2,
                viewerZoomAltitude: 5000000,
                viewerZoomDelay: 1,
                appStartLongitude: -98.579,
                appStartLatitude: 41.828,
                removeProminentProfileDelay: 200,
                logWhenSleepingForLongerThanDuration: 5000,
                billboardRenderedProminentProfile: false,
                prominentProfilePushpinAltitude: 20000,
                prominentProfilePushpinPixelSize: 25,
                profilePushpinPixelSize: 15,
                bingMapsApiKey: '',
                profilesApiUrl: 'https://localhost:44311/api/Profiles',
                profilesApiKey: '983D7DB4-3C56-40D3-AF73-E523906D3106'
            });
})();