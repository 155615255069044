(function () {
    'use strict';

    angular
        .module('app')
        .controller('ProminentProfileController', ProminentProfileController);

    ProminentProfileController.$inject = ['$timeout','profileSimulationConfig', 'viewerCustomization','prominentProfileNotification']; 

    function ProminentProfileController($timeout, profileSimulationConfig, viewerCustomization, prominentProfileNotification) {
        /* jshint validthis:true */
        var vm = this;
        
        vm.colorAsRgb = colorAsRgb;
        vm.showAccountFilter = showAccountFilter;
        vm.showConsumerFilter = showConsumerFilter;
        vm.profile = null;
        vm.newProfileArrived = newProfileArrived;
        prominentProfileNotification.subscribe(vm);

        function colorAsRgb(profileColor) {
            return 'rgb(' + profileColor.red + ',' + profileColor.green + ',' + profileColor.blue + ')';
        }

        function showAccountFilter(account) {
            return viewerCustomization.shouldDisplayAccount(account);
        }
        function showConsumerFilter(account) {
            return viewerCustomization.shouldDisplayConsumer(account);
        }
        function newProfileArrived(profile) {
            vm.profile = null;
            $timeout(function() {
                vm.profile = profile;
            }, (profileSimulationConfig.viewerZoomDuration - 0.5) * 1000);
        }
    }
})();
