(function () {
    'use strict';

    angular
        .module('app')
        .controller('ProfilesJobsController', ProfilesJobsController);

    ProfilesJobsController.$inject = ['profileNotification', 'profileSynchronization','pause','$log'];

    function ProfilesJobsController(
        profileNotification,
        profileSynchronization,
        pause,
        $log)
    {
        /* jshint validthis:true */
        var vm = this;
        vm.pause = pause;
        vm.startSync = startSynchronization;
        vm.canSync = false;

        profileNotification.run();
        startSynchronization();

        function startSynchronization() {
            vm.canSync = false;
            vm.synchronizationError = "";
            profileSynchronization
                .run()
                .catch(initialSynchronizationFailed);
        }
        function initialSynchronizationFailed(error) {
            $log.warn(error);
            vm.synchronizationError = "No profiles are available and the initial synchronization has failed.";
            vm.canSync = true;
        }
    }
})();
