(function () {
    'use strict';

    angular
        .module('app')
        .config(httpProviderConfiguration, locationProviderConfiguration);

    httpProviderConfiguration.$inject = ['$httpProvider'];
    locationProviderConfiguration.$inject = ['$locationProvider'];

    function httpProviderConfiguration($httpProvider) {

        $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

        $httpProvider.defaults.transformResponse.push(function (obj) {
            return transformIso8601DateTextValuesToJsDates(obj);
        });

        function transformIso8601DateTextValuesToJsDates(obj) {
            var iso8601RegEx = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)/;
            var propertyName,
                propertyValue,
                match,
                dateMoment;

            if (!angular.isObject(obj)) return obj;

            for (propertyName in obj) {
                if (!obj.hasOwnProperty(propertyName)) continue;
                propertyValue = obj[propertyName];

                if (angular.isString(propertyValue) && (match = propertyValue.match(iso8601RegEx))) {
                    dateMoment = moment(match[0]);
                    if (dateMoment.isValid()) obj[propertyName] = dateMoment.toDate();
                } else if (angular.isObject(propertyValue)) {
                    transformIso8601DateTextValuesToJsDates(propertyValue);
                }
            }
            return obj;
        }
    }

    function locationProviderConfiguration($locationProvider) {
        $locationProvider.html5Mode(true)
                         .hashPrefix('!');
    }
})();