(function () {
    'use strict';

    angular
        .module('app')
        .factory('profileDataUpdate', profileDataUpdate);

    profileDataUpdate.$inject = ['profileData','persistedProfiles'];

    function profileDataUpdate(profileData, persistedProfiles) {
        /* jshint validthis:true */
        var service = {
            applyUpdateBatch: applyUpdateBatch,
            getLastAnchor: getLastAnchor,
            hasData: hasData
        }
        return service;

        function getLastAnchor() {
            return profileData.anchor;
        }
        function hasData() {
            return profileData.anchor.length !== 0;
        }
        function applyUpdateBatch(newProfileBatch) {
            profileData.anchor = newProfileBatch.anchor;
            var previousLength = profileData.profiles.length;
            if (newProfileBatch.profiles.length > 0) {
                profileData.profiles =
                    newProfileBatch.profiles.concat(profileData.profiles);
            }
            var profilesToRemove = profileData.profiles.length - previousLength;
            if (previousLength > 0 && profilesToRemove > 0) {
                profileData.profiles.splice(previousLength, profilesToRemove);
            }

            profileData.maximumProfileCount = newProfileBatch.totalNumberOfProfilesTaken;
            profileData.startingProfileCount = profileData.maximumProfileCount - profileData.profiles.length;

            persistedProfiles.save(
                profileData.anchor,
                profileData.maximumProfileCount,
                profileData.profiles);
        }
    }
})();
