(function() {
    'use strict';

    angular.module('app')
        .service('profileSynchronization', profileSynchronization);

    profileSynchronization.$inject = ['sleep', 'profileWebClient', 'profileSimulationConfig', 'profileDataUpdate', 'persistedProfiles','$q','$log'];

    function profileSynchronization(sleep, profileWebClient, profileSimulationConfig, profileDataUpdate, persistedProfiles, $q, $log) {
        /* jshint validthis:true */
        var service = {
            run: startSynchronizing
        }
        return service;

        function startSynchronizing() {
            if (initializeFromLocalData()) {
                return $q.resolve();
            }
            return synchronizeInitialBatch();
        }

        function initializeFromLocalData() {
            var localData = persistedProfiles.load();
            if (localData) {
                applyUpdateAndResyncAfterPause(localData);
                return true;
            }
            return false;
        }

        function synchronizeInitialBatch() {
            return profileWebClient
                .getInitialSetOfProfiles()
                .then(applyUpdateAndResyncAfterPause);
        }
        function synchronizeNextBatch() {
            profileWebClient
                .getNextBatchOfProfiles(profileDataUpdate.getLastAnchor())
                .then(applyUpdateAndResyncAfterPause)
                .catch(retryResyncAfterPause);
        }

        function applyUpdateAndResyncAfterPause(newProfileBatch) {
            profileDataUpdate.applyUpdateBatch(newProfileBatch);
            pauseAndSync();
        }

        function retryResyncAfterPause(error) {
            $log.warn(error);
            pauseAndSync();
        }
        function pauseAndSync() {
            sleep
                .forMilliseconds(profileSimulationConfig.sleepTimeBetweenSync)
                .because('pause between sync')
                .thenRun(synchronizeNextBatch);
        }
    }
})();