(function() {
    'use strict';

    angular.module('app')
        .config(function($provide) {
            $provide.decorator('prominentProfileVisualizer', 
                ['$delegate', '$timeout', 'profileSimulationConfig', cesiumProminentPushpinVisualizer]);
        });

    function cesiumProminentPushpinVisualizer($delegate, $timeout, profileSimulationConfig) {
        var decorated = $delegate;
        var profileViewer = {};
        var previousPushpin = null;
        var service = {
            newProfileArrived: newProfileArrived,
            initialize: initialize
        }
        return service;

        function initialize(cesiumViewer) {
            profileViewer = cesiumViewer;
            decorated.initialize(cesiumViewer);
        }

        function newProfileArrived(profile) {
            decorated.newProfileArrived(profile);
            if (previousPushpin) {
                var toRemove = previousPushpin;
                $timeout(function() {
                    profileViewer.entities.remove(toRemove);
                }, profileSimulationConfig.removeProminentProfileDelay);
            }
            previousPushpin =
                profileViewer.entities.add({
                    position: Cesium.Cartesian3.fromDegrees(
                        profile.coordinates.longitude,
                        profile.coordinates.latitude,
                        profileSimulationConfig.prominentProfilePushpinAltitude),
                    point: {
                        pixelSize: profileSimulationConfig.prominentProfilePushpinPixelSize,
                        color: Cesium.Color.fromBytes(
                            profile.color.red,
                            profile.color.green,
                            profile.color.blue)
                    }
                });
        }
        
    }

})();
