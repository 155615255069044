(function() {
    'use strict';

    angular.module('app')
        .config(function($provide) {
            $provide.decorator('prominentProfileVisualizer', cesiumProfileCanvasVisualizer);
        });
    function cesiumProfileCanvasVisualizer($delegate, $timeout, viewerCustomization, profileSimulationConfig) {
        var decorated = $delegate;
        var profileViewer = {};
        var prominentProfile = null;
        var prominentProfilesDisplayed = 0;
        var service = {
            newProfileArrived: newProfileArrived,
            initialize: initialize
        }
        return service;

        function initialize(cesiumViewer) {
            profileViewer = cesiumViewer;
            decorated.initialize(cesiumViewer);
        }

        function newProfileArrived(profile) {
            decorated.newProfileArrived(profile);

            if (!profileSimulationConfig.billboardRenderedProminentProfile)
                return;

            var previousProminentProfile = prominentProfile;
            prominentProfile = addProminentProfile(profile);
            prominentProfilesDisplayed++;
            $timeout(function() {
                    if (previousProminentProfile) {
                        profileViewer.entities.remove(previousProminentProfile);
                    }
                },
                profileSimulationConfig.removeProminentProfileDelay);
        }

        function drawBorder(context, width, height, cornerRadius) {
            context.lineWidth = 6;
            context.beginPath();
            context.moveTo(cornerRadius, 0);
            context.arcTo(width, 2, width, height, cornerRadius);
            context.arcTo(width, height - cornerRadius, 2, height, cornerRadius);
            context.arcTo(2, height - cornerRadius, 2, 2, cornerRadius);
            context.arcTo(2, 2, width, 2, cornerRadius);
            context.closePath();
            context.fill();
            context.stroke();
        }

        function createProfileImage(profile) {
            var showAccount = viewerCustomization.shouldDisplayAccount(profile.account);
            var showConsumer = viewerCustomization.shouldDisplayConsumer(profile.account);

            var canvas = document.createElement('canvas');
            canvas.width = 274;
            canvas.height = 184;
            var context = canvas.getContext("2d");
            context.fillStyle = "#000000CC";

            var consumerHeight =
                showConsumer ? 30 : 0;
            var accountHeight =
                showAccount ? 20 : 0;
            var width = 274;
            var height = 110 + consumerHeight  + accountHeight;
            var cornerRadius = 16;
            context.strokeStyle =
                "#" +
                profile.color.red.toString(16) +
                profile.color.green.toString(16) +
                profile.color.blue.toString(16);
            drawBorder(context, width, height, cornerRadius);

            if (showConsumer) {
                context.font = "30px verdana";
                context.fillStyle = "#ffffff";
                context.fillText(profile.consumer, 12, 40);
            }

            if (showAccount) {
                context.font = "24px verdana";
                context.fillText(profile.account, 12, 66);
            }

            context.font = "16px verdana";
            context.fillText(profile.city, 12, 66 + accountHeight + consumerHeight);
            context.fillText(profile.state, 12, 86 + accountHeight + consumerHeight);
            context.fillText(profile.country, 12, 106 + accountHeight + consumerHeight);

            return canvas;
        }

        function addProminentProfile(profile) {
            var image = createProfileImage(profile);
            return profileViewer.entities.add({
                position: Cesium.Cartesian3.fromDegrees(profile.coordinates.longitude, profile.coordinates.latitude),
                billboard: {
                    image: image,
                    width: image.width + 2,
                    height: image.height,
                    verticalOrigin: Cesium.VerticalOrigin.TOP,
                    horizontalOrigin: Cesium.HorizontalOrigin.CENTER
                }
            });
        }
    }

})();
