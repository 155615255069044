(function() {
    'use strict';

    angular.module('app')
        .factory('pushPinDisplay', pushPinDisplay);

    pushPinDisplay.$inject = ['profileNotification','profileSimulationConfig'];

    function pushPinDisplay(profileNotification, profileSimulationConfig) {
        /* jshint validthis:true */
        var profileViewer = {};
        var visiblePushpins = [];
        var service = {
            initialize: initialize
        }
        return service;

        function initialize(cesiumViewer) {
            profileViewer = cesiumViewer;
            profileNotification.subscribe(addPushPin);
        }

        function hasValidCoordinates(profileCoordinates) {
            var invalidCoordinates = { longitude: 0, latitude: 0 };
            return !angular.equals(profileCoordinates, invalidCoordinates);
        }

        function addPushPin(profile) {
            if (!hasValidCoordinates(profile.coordinates)) return;
            var newPushPinEntity =
                profileViewer.entities.add({
                    position: Cesium.Cartesian3.fromDegrees(
                        profile.coordinates.longitude,
                        profile.coordinates.latitude),
                    point: {
                        pixelSize: profileSimulationConfig.profilePushpinPixelSize,
                        color: Cesium.Color.fromBytes(
                            profile.color.red,
                            profile.color.green,
                            profile.color.blue)
                    }
                });
            visiblePushpins.push(newPushPinEntity);
            while (visiblePushpins.length > profileSimulationConfig.maximumNumberOfPushPins) {
                var toRemove = visiblePushpins.shift();
                profileViewer.entities.remove(toRemove);
            }
        }
    }
})();