(function() {
    'use strict';

    angular.module('app')
        .service('profileNotification', profileNotification);

    profileNotification.$inject = ['profileProducer', 'sleep', 'profileSimulationConfig'];

    function profileNotification(profileProducer, sleep, profileSimulationConfig) {
        /* jshint validthis:true */
        var subscribers = [];
        var service = {
            run: showNextProfile,
            subscribe: subscribe
        };

        return service;

        function subscribe(newProfileArrivedCallback) {
            subscribers.push(newProfileArrivedCallback);
        }

        function onNewProfileArrived(currentProfile) {
            subscribers.forEach(function(observer) { observer(currentProfile); });
        }

        function showNextProfile() {
            if (!profileProducer.hasProfiles()) {
                sleep
                    .forMilliseconds(profileSimulationConfig.sleepTimeWhenNoProfilesAvailable)
                    .because('profiles not yet available')
                    .thenRun(showNextProfile);
                return;
            }

            var currentProfile = profileProducer.takeNextProfile();
            var nextProfile = profileProducer.peekNextProfile();

            var timeDiff = nextProfile.date - currentProfile.date;
            var msTillNext = timeDiff / profileSimulationConfig.realtimeIterationRate;
            if (msTillNext < 0) {
                msTillNext =
                    Math.floor(Math.random() * profileSimulationConfig.maxGapBetweenUpdate);
            }
            var sleepInterval = Math.min(
                profileSimulationConfig.maxGapBetweenUpdate,
                msTillNext);
            onNewProfileArrived(currentProfile);
            sleep
                .forMilliseconds(sleepInterval)
                .because('waiting for next profile')
                .thenRun(showNextProfile);
        }
    }
})();
