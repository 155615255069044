(function () {
    'use strict';

    angular.module('app', [
        'ngAnimate',
        
        'ui.bootstrap',
        'toaster',
        'angularMoment'
    ]);
})();