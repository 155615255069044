(function () {
    'use strict';

    angular
        .module('app')
        .factory('webApiFactory', webApiFactory);

    webApiFactory.$inject = ['$http', 'urls'];

    function webApiFactory($http, urls) {
        var service = {
            getData: getData,
            postData: postData,
            deleteData: deleteData
        };

        function buildUrl(controller, method) {
            return urls.apiRoot + controller + '/' + method;
        }

        function getData(controller, method, params) {
            var url = buildUrl(controller, method);
            var config = { params: params };

            return $http.get(url, config);
        }

        function postData(controller, method, data) {
            var url = buildUrl(controller, method);

            return $http.post(url, data);
        }

        function deleteData(controller, method, dataId) {
            var url = buildUrl(controller, method);
            var config = { params: { id: dataId } };

            return $http.delete(url, config);
        }

        return service;
    }
})();