(function () {
    'use strict';

    angular
        .module('app')
        .factory('persistedProfiles', persistedProfiles);
    persistedProfiles.$inject = ['$window'];
    function persistedProfiles($window) {
        /* jshint validthis:true */
        var storageDevice = $window.localStorage;
        var service = {
            save: save,
            load: load,
            reset: reset
        }

        return service;

        function save(anchor, totalNumberOfProfilesTaken, profiles) {
            storageDevice.setItem(
                'anchor',
                angular.toJson(anchor));
            storageDevice.setItem(
                'totalNumberOfProfilesTaken',
                totalNumberOfProfilesTaken);
            storageDevice.setItem(
                'profiles',
                angular.toJson(profiles));
        }
        function transformProfiles(profiles) {
            if (!profiles) {
                return profiles;
            }

            var results = [];
            profiles.forEach(function(profile) {
                var updated = angular.copy(profile);
                updated.date = new Date(profile.date);
                results.push(updated);
            });
            return results;
        }
        function isValid(results) {
            return results &&
                results.anchor &&
                results.profiles;
        }
        function load() {
            var anchor =
                angular.fromJson(storageDevice.getItem('anchor'));
            var maximumProfileCount =
                storageDevice.getItem('totalNumberOfProfilesTaken');
            var profiles =
                transformProfiles(
                    angular.fromJson(storageDevice.getItem('profiles')));
            var results = {
                profiles: profiles,
                totalNumberOfProfilesTaken: maximumProfileCount,
                anchor: anchor
            }
            return isValid(results) ? results : undefined;
        }
        function reset() {
            storageDevice.removeItem('anchor');
            storageDevice.removeItem('totalNumberOfProfilesTaken');
            storageDevice.removeItem('profiles');
        }
    }
})();
