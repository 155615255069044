(function () {
    'use strict';

    angular
        .module('app')
        .value('urls',
            {
                messageModalTemplateUrl: 'messageModalTemplate.html',
                messageModalConfirmTemplateUrl: 'messageModalConfirmTemplate.html'
            });

    angular
        .module('app')
        .run(['$templateCache', 'urls', addMessageModalTemplatesToCache]);

    angular
        .module('app')
        .factory('notification', messageModalNotification);

    messageModalNotification.$inject = ['$injector', '$log', '$rootScope', '$sce', 'urls'];

    function messageModalNotification($injector, $log, $rootScope, $sce, urls) {
        var templateUrl = urls.messageModalTemplateUrl,
            $uibModal,
            vm = {},
            service = {
                showError: showErrorModal,
                showInfo: showInfoModal,
                showConfirm: showConfirmModal
            };

        vm.closeModal = closeModal;
        vm.confirmOk = confirmOk;
        vm.cancel = cancel;
        vm.iconClasses = getIconClassMap();
        vm.message = [];
        vm.modal = {};
        vm.title = '';

        return service;

        function closeModal() {
            if (!vm.modal || !vm.modal.close) return;
            $log.info('Closing modal');
            vm.modal.close();
        }

        function confirmOk() {
            if (!vm.onOk) return;
            vm.onOk();
            closeModal();            
        }

        function cancel() {
            if (!vm.onCancel) closeModal();
            return vm.onCancel;
        }

        function ensureModalServiceInitialized() {
            if (!$uibModal) $uibModal = $injector.get('$uibModal');
        }

        function getIconClassMap() {
            return {
                "fa": true,
                "pull-right": true,
                "text-danger": false,
                "text-info": false,
                "fa-exclamation-circle": false,
                "fa-question-circle": false,
        };
        }

        function showErrorModal(title, message) {
            $log.info('Showing error modal');
            vm.iconClasses['text-danger'] = true;
            vm.iconClasses['fa-exclamation-circle'] = true;
            vm.message = $sce.trustAsHtml(message);
            vm.title = title;
            showModal();
        }

        function showInfoModal(title, message) {
            $log.info('Showing info');
            vm.iconClasses['text-info'] = true;
            vm.message = $sce.trustAsHtml(message);
            vm.title = title;
            showModal();
        }

        function showConfirmModal(title, message, okCallback, cancelCallback) {
            $log.info('Showing info');
            vm.iconClasses['text-danger'] = true;
            vm.iconClasses['fa-question-circle'] = true;
            vm.message = $sce.trustAsHtml(message);
            vm.title = title;
            vm.onOk = okCallback;
            vm.onCancel = cancelCallback;

            templateUrl = urls.messageModalConfirmTemplateUrl;
            showModal();            
        }

        function showModal() {
            ensureModalServiceInitialized();

            $rootScope.messageModal = vm;
            vm.modal = $uibModal.open({
                templateUrl: templateUrl,
                scope: $rootScope
            });
        }
    }

    function addMessageModalTemplatesToCache($templateCache, urls) {
        $templateCache.put(
            urls.messageModalTemplateUrl, 
            '<div>' +
            '   <section class="modal-header">' +
            '       <button type="button" class="close" aria-hidden="true" data-ng-click="messageModal.closeModal()">&times;</button>' +
            '       <span class="modal-title">{{ messageModal.title }}</span>' +
            '   </section>' +
            '   <section class="modal-body">' +
            '       <div class="row">' +
            '           <div class="row">' +
            '               <div class="col-md-2" style="font-size: 40pt">' +
            '                   <span ng-class="messageModal.iconClasses" aria-hidden="true"></span>' +
            '               </div>' +
            '               <div class="col-md-10">' +
            '                   <p ng-bind-html="messageModal.message"></p>' +
            '               </div>' +
            '           </div>' +
            '       </div>' +
            '   </section>' +
            '   <section class="modal-footer">' +
            '       <div>' +
            '           <button class="btn btn-primary" data-ng-click="messageModal.closeModal()">Close</button>' +
            '       </div>' +
            '   </section>' +
            '</div>');

        $templateCache.put(
            urls.messageModalConfirmTemplateUrl,
            '<div>' +
            '   <section class="modal-header">' +
            '       <button type="button" class="close" aria-hidden="true" data-ng-click="messageModal.closeModal()">&times;</button>' +
            '       <span class="modal-title">{{ messageModal.title }}</span>' +
            '   </section>' +
            '   <section class="modal-body">' +
            '       <div class="row">' +
            '           <div class="row">' +
            '               <div class="col-md-2" style="font-size: 40pt">' +
            '                   <span ng-class="messageModal.iconClasses" aria-hidden="true"></span>' +
            '               </div>' +
            '               <div class="col-md-10">' +
            '                   <p ng-bind-html="messageModal.message"></p>' +
            '               </div>' +
            '           </div>' +
            '       </div>' +
            '   </section>' +
            '   <section class="modal-footer">' +
            '       <div class="row">' +
            '           <div class="col-md-12 search-buttons">' +
            '               <button class="btn btn-primary" data-ng-click="messageModal.confirmOk()">Ok</button>' +
            '               <button class="btn btn-default" data-ng-click="messageModal.cancel()">Cancel</button>' +
            '           </div>' +
            '       </div>' +
            '   </section>' +
            '</div>');
    }
})();