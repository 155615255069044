(function() {
    'use strict';

    angular
        .module('app')
        .factory('ajaxErrorHandler', ajaxErrorHandler);

    ajaxErrorHandler.$inject = ['$log', '$q', 'notification'];

    function ajaxErrorHandler($log, $q, notification) {
        var genericErrorTitleText = 'An Error Occurred';
        var genericErrorMessageText = 'There was a problem processing the last operation.  Please re-try the operation and contact support if the problem persists.';
        var httpErrorHandlers = initHttpErrorHandlers(),
            service = {
                request: request,
                requestError: requestError,
                response: response,
                responseError: handleAjaxError,
                setHttpErrorHandlerForStatusCode: setHttpErrorHandlerForStatusCode
            };

        return service;

        function getHandlerKey(statusCode) {
            return 'Http' + statusCode.toString();
        }

        function handleAjaxError(rejection) {
            $log.info('Handling ajax error with status code ' + rejection.status);
            var handler = httpErrorHandlers[getHandlerKey(rejection.status)] || httpErrorHandlers.default;
            handler(rejection);
            return $q.reject(rejection);
        }

        function initHttpErrorHandlers() {
            return {
                'default': genericHttpErrorHandler,
                'Http500': serverErrorHandler,
                'Http422': unprocessableEntityHandler,
                'Http-1': authenticationNeededHandler
            };
        }

        function genericHttpErrorHandler() {
            notification.showError(genericErrorTitleText, genericErrorMessageText);
        }

        function serverErrorHandler(rejection) {
            if (rejection.data && rejection.data.exceptionMessage) {
                notification.showError(genericErrorTitleText, rejection.data.exceptionMessage);
            } else {
                return genericHttpErrorHandler();
            }
        }

        function unprocessableEntityHandler(rejection) {
            if (rejection.data && rejection.data.errors) {
                notification.showError(genericErrorTitleText, '<ul><li>' + rejection.data.errors.map(function (elem) {
                    return elem.detail;
                }).join('</li><li>') + '</li></ul>');
            } else {
                return genericHttpErrorHandler();
            }
        }

        function authenticationNeededHandler(rejection) {
            $log.info(rejection);
        }

        function setHttpErrorHandlerForStatusCode(statusCode, handler) {
            httpErrorHandlers[getHandlerKey(statusCode)] = handler;
        }

        function request(config) {
            return config;
        }

        function requestError(rejection) {
            return $q.reject(rejection);
        }

        function response(resp) {
            return resp;
        }
    }
})();