(function () {
    'use strict';

    angular
        .module('app')
        .controller('ProfileCounterController', ProfileCounterController);

    ProfileCounterController.$inject = ['profileProducer', 'profileNotification'];

    function ProfileCounterController(profileProducer, profileNotification) {
        /* jshint validthis:true */
        var vm = this;
        vm.totalProfileCount = 0;

        profileNotification.subscribe(newProfileArrived);

        function newProfileArrived() {
            vm.totalProfileCount += 1;
            var profileCountRange = profileProducer.getProfileCountRange();
            if (vm.totalProfileCount > profileCountRange.maximumProfileCount ||
                vm.totalProfileCount < profileCountRange.startingProfileCount) {
                vm.totalProfileCount = profileCountRange.startingProfileCount;
            }
        }
    }
})();
