(function () {
    'use strict';

    angular
        .module('app')
        .factory('viewerLogo', viewerLogo);

    viewerLogo.$inject = ['$http'];

    function viewerLogo($http) {
        return {
            getLogo: getLogo
        };
        
        function getLogo(logo) {
            return $http.get('/images/' + logo, { responseType: 'arraybuffer' });
        }
    }
})();