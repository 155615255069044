(function () {
    'use strict';

    angular
        .module('app')
        .factory('profileWebClient', profileWebClient);

    profileWebClient.$inject = ['$http', 'profileSimulationConfig'];

    function profileWebClient($http, profileSimulationConfig) {
        var service = {
            getInitialSetOfProfiles: getInitialSetOfProfiles,
            getNextBatchOfProfiles: getNextBatchOfProfiles
        };

        return service;


        function getInitialSetOfProfiles() {
            if (profileSimulationConfig.useOfflineProfiles) {
                return getInitialSetOfProfilesOffline();
            } else {
                return getInitialSetOfProfilesOnline();
            }
        }

        function getInitialSetOfProfilesOffline() {
            return $http
                .get('/InitialProfileBatch.json')
                .then(function (resp) {
                    return resp.data;
                });
        }

        function getInitialSetOfProfilesOnline() {
            return $http
                .get(profileSimulationConfig.profilesApiUrl, { headers: { 'ApiKey': profileSimulationConfig.profilesApiKey } })
                .then(function(resp) {
                    return resp.data;
                });
        }

        function getNextBatchOfProfiles(anchor) {
            return $http
                .post(profileSimulationConfig.profilesApiUrl,{ anchor: anchor }, { headers: { 'ApiKey': profileSimulationConfig.profilesApiKey } })
                .then(function (resp) {
                    return resp.data;
                });
        }
    }
})();