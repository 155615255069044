(function() {
    'use strict';

    angular
        .module('app')
        .directive('profileGlobe', profileGlobe);

    profileGlobe.$inject = [];

    function profileGlobe() {
        return {
            restrict: 'EA',
            controller: ProfileGlobeController,
            controllerAs: 'profileGlobeController'
        }
    }

    ProfileGlobeController.$inject = ['$element', 'profileSimulationConfig', 'pushPinDisplay', 'prominentProfileNotification', '$timeout','prominentProfileVisualizer'];

    function ProfileGlobeController(
        $element,
        profileSimulationConfig,
        pushPinDisplay,
        prominentProfileNotification,
        $timeout,
        prominentProfileVisualizer)
    {

        var cesiumViewerConfig = {
            fullscreenButton: false,
            animation: false,
            timeline: false,
            homeButton: false,
            sceneModePicker: false,
            navigationHelpButton: false,
            navigationInstructionsInitiallyVisible: false,
            geocoder: false,
            baseLayerPicker: false,
            vrButton: false,
            infoBox: false,
            selectionIndicator: false
        };
        if (profileSimulationConfig.useOfflineMaps) {
            cesiumViewerConfig.imageryProvider = Cesium.createTileMapServiceImageryProvider({
                url: Cesium.buildModuleUrl('Assets/Textures/NaturalEarthII')
            });
        } else {
            Cesium.BingMapsApi.defaultKey = profileSimulationConfig.bingMapsApiKey;
        }
        var profileViewer = new Cesium.Viewer($element[0], cesiumViewerConfig);

        pushPinDisplay.initialize(profileViewer);
        prominentProfileVisualizer.initialize(profileViewer);
        profileViewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(
                profileSimulationConfig.appStartLongitude,
                profileSimulationConfig.appStartLatitude,
                profileSimulationConfig.viewerZoomAltitude),
            duration: profileSimulationConfig.viewerZoomDuration,
            complete: function () {
                prominentProfileNotification.subscribe(
                    prominentProfileVisualizer);
            }
        });
    }
})();