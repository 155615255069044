(function() {
    'use strict';

    angular.module('app')
        .factory('prominentProfileVisualizer', cesiumProfileFlybyVisualizer);
    
    cesiumProfileFlybyVisualizer.$inject = ['profileSimulationConfig'];

    function cesiumProfileFlybyVisualizer(profileSimulationConfig) {
        var profileViewer = {};
        var service = {
            newProfileArrived: newProfileArrived,
            initialize:initialize
        }
        return service;

        function initialize(cesiumViewer) {
            profileViewer = cesiumViewer;
        }
        function newProfileArrived(profile) {
            profileViewer.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(
                    profile.coordinates.longitude,
                    profile.coordinates.latitude,
                    profileSimulationConfig.viewerZoomAltitude),
                duration: profileSimulationConfig.viewerZoomDuration
            });
        }
    }
})();
