(function () {
    'use strict';

    angular
        .module('app')
        .controller('RecentProfilesController', RecentProfilesController);

    RecentProfilesController.$inject = ['profileNotification', 'profileSimulationConfig', 'viewerCustomization']; 

    function RecentProfilesController(profileNotification, profileSimulationConfig, viewerCustomization) {
        /* jshint validthis:true */
        var vm = this;
        vm.profilesDisplayed = [];
        vm.accountDisplay = '';
        vm.accountList = [];

        vm.colorAsRgb = colorAsRgb;
        vm.showAccountFilter = showAccountFilter;
        vm.showConsumerFilter = showConsumerFilter;

        profileNotification.subscribe(newProfileArrived);
        
        function newProfileArrived(currentProfile) {
            vm.profilesDisplayed.unshift(currentProfile);
            while (vm.profilesDisplayed.length > profileSimulationConfig.maximumRecentProfileCount) {
                vm.profilesDisplayed.pop();
            }
        }

        function colorAsRgb(profileColor) {
            return 'rgb(' + profileColor.red + ',' + profileColor.green + ',' + profileColor.blue + ')';
        }

        function showAccountFilter(account) {
            return viewerCustomization.shouldDisplayAccount(account);
        }

        function showConsumerFilter(account) {
            return viewerCustomization.shouldDisplayConsumer(account);
        }
    }
})();
