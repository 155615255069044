(function () {
    'use strict';

    angular
        .module('app')
        .factory('profileProducer', profileProducer);
    profileProducer.$inject = ['profileData'];
    function profileProducer(profileData) {

        var service = {
            hasProfiles: hasProfiles,
            takeNextProfile: takeNextProfile,
            peekNextProfile: peekNextProfile,
            getProfileCountRange: getRangeOfProfileCount
        }
        return service;



        function hasProfiles() {
            return profileData.profiles.length > 1;
        }

        function peekNextProfile() {
            return profileData.profiles[0];
        }

        function takeNextProfile() {
            var nextProfile = profileData.profiles.shift();
            profileData.profiles.push(nextProfile);
            return nextProfile;
        }

        function getRangeOfProfileCount() {
            return {
                maximumProfileCount: profileData.maximumProfileCount,
                startingProfileCount: profileData.startingProfileCount
            };
        }
    }
})();
