(function() {
    'use strict';

    angular
        .module('app')
        .factory('sleep', sleep);

    sleep.$inject = ['$timeout', 'pause', 'profileSimulationConfig','$log'];

    function sleep($timeout, pause, profileSimulationConfig,$log) {
        /* jshint validthis:true */
        var service = {
            forMilliseconds: sleepforMilliseconds
        }

        return service;

        function sleepforMilliseconds(sleepInterval) {

            var continuation = function(actionAfterSleep) {
                $timeout(
                    function () {
                        if (pause.isPaused) {
                            continueSleepingWhenPaused(actionAfterSleep);
                        } else {
                            actionAfterSleep();
                        }
                    },
                    sleepInterval);
            };

            return {
                because: function(reason) {
                    return {
                        thenRun: function (actionAfterSleep) {
                            if (sleepInterval > profileSimulationConfig.logWhenSleepingForLongerThanDuration) {
                                $log.info("Sleeping for " + sleepInterval + "ms.  Reason: " + reason);
                            }
                            continuation(actionAfterSleep);
                        }
                    };
                },
                thenRun: continuation
            };
        }

        function continueSleepingWhenPaused(actionAfterSleep) {
            sleepforMilliseconds(profileSimulationConfig.sleepWhenPaused)
                .because('paused')
                .thenRun(actionAfterSleep);
        }
    }
})();
