(function() {
    'use strict';

    angular.module('app')
        .factory('prominentProfileNotification', prominentProfileNotification);

    prominentProfileNotification.$inject = ['$timeout', 'profileNotification', 'profileSimulationConfig'];

    function prominentProfileNotification($timeout, profileNotification, profileSimulationConfig) {
        /* jshint validthis:true */
        var subscribers = [];
        var isNavigating = false;

        profileNotification.subscribe(newProfileArrived);

        var service = {
            subscribe: subscribe,
        }
        return service;

        function subscribe(subscriber) {
            subscribers.push(subscriber);
        }
        function notifyNoLongerNavigating() {
            isNavigating = false;
        }
        function newProfileArrived(profile) {
            if (isNavigating)
                return;
            if (profile.coordinates.longitude === 0 &&
                profile.coordinates.latitude === 0)
                return;
            isNavigating = true;
            subscribers.forEach(function(subscriber) { subscriber.newProfileArrived(profile); });
            $timeout(
                notifyNoLongerNavigating,
                profileSimulationConfig.viewerZoomDuration*1000
                + profileSimulationConfig.viewerZoomDelay*1000);
        }
    }
})();