(function () {
    'use strict';

    angular
        .module('app')
        .factory('pause', pause);

    pause.$inject = ['profileSimulationConfig'];

    function pause(profileSimulationConfig) {
        /* jshint validthis:true */

        var service = {
            isPaused: !profileSimulationConfig.autoStartProfileSync,
            togglePause: togglePause
        }

        return service;

        function togglePause() {
            service.isPaused = !service.isPaused;
        }
    }
})();
