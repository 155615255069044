(function () {
    'use strict';

    angular
        .module('app')
        .controller('ImageController', ImageController);

    ImageController.$inject = ['$location', 'viewerCustomization'];

    function ImageController($location, viewerCustomization) {
        /* jshint validthis:true */
        var vm = this;
        vm.logoDisplay = '';
        
        activate();

        function activate() {
            viewerCustomization.getLogo()
                .then(function(response) {
                    vm.logoDisplay = response;
                });
        }
    }
})();
