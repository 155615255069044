(function () {
    'use strict';

    angular
        .module('app')
        .factory('profileData', profileData);

    profileData.$inject = [];

    function profileData() {
        /* jshint validthis:true */
        return {
            profiles: [],
            anchor: [],
            maximumProfileCount: 0,
            startingProfileCount: 0
        }
    }
})();
